import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
import { Pagination } from '../table/pagination';
import { useSort } from '@table-library/react-table-library/sort';
import {Table, Header, HeaderRow, HeaderCell, Body, Row, Cell} from '@table-library/react-table-library/table';
import { TableWrapper } from './style';

function DevStatusTable({data, limit, getUser}) {
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: limit
    }
  });
  const sort = useSort(
    data,
    {
      state: {
        sortKey: 'name',
        reverse: false
      }
    },
    {
      sortFns: {
        name: (array) => array.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
      }
    }
  );
  const theme = useTheme({
		BaseCell: `
			&:nth-of-type(1) {
				left: 0px;
			}`,
	});
  return (
      <TableWrapper>
        <Table
          data={data}
          pagination={pagination}
          className='table'
          role='grid'
          data-toggle='data-table'
          theme={theme}
          sort={sort}
          style={{width: '100vw'}}
        >
          {
            (tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell sortKey="name" pinLeft>Nome</HeaderCell>
                    <HeaderCell sortKey="backlog">Backlog</HeaderCell>
                    <HeaderCell sortKey="planejado">Planejado</HeaderCell>
                    <HeaderCell sortKey="fazendo">Fazendo</HeaderCell>
                    <HeaderCell sortKey="atual_planejado">Atual Planejado</HeaderCell>
                    <HeaderCell sortKey="executado">Executado</HeaderCell>
                    <HeaderCell sortKey="paralizado">Paralisado</HeaderCell>
                  </HeaderRow>
                </Header>
                <Body>
                  {
                    tableList.map((dev, index) => (
                      <>
                        <Row key={index} pinLeft item={dev}>
                          <Cell onClick={() => getUser(dev)}>{dev.name}</Cell>
                          <Cell>{dev.backlog}</Cell>
                          <Cell>{dev.planned}</Cell>
                          <Cell title={dev.doing.join(', ')}>{dev.doing.length}</Cell>
                          <Cell>{dev.planned_doing}</Cell>
                          <Cell>{dev.executed}</Cell>
                          <Cell>{dev.paralized}</Cell>
                        </Row>
                      </>
                    ))
                  }
                </Body>
              </>
            )
          }
        </Table>
        <Pagination data={data} pagination={pagination}/>
      </TableWrapper>
  );
}

export {DevStatusTable};